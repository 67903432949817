import React, { Component } from 'react'

class CreditReportMn extends Component {
  componentDidMount () {
  }

  render () {
    return (
      <>
      </>
    )
  }
}

export default CreditReportMn
